import React, { Component } from 'react'
import Select from 'react-select'

const priceMin = [
  { label: "Any", value: ''},
  { label: "$300,000", value: 300000},
  { label: "$500,000", value: 500000},
  { label: "$700,000", value: 700000},
  { label: "$900,000", value: 900000},
  { label: "$1,000,000", value: 1000000},
  { label: "$1,500,000", value: 1500000},
  { label: "$2,000,000", value: 2000000},
  { label: "$2,500,000", value: 2500000},
  { label: "$3,000,000", value: 3000000}
]

const priceMax = [
  { label: "Any", value: ''},
  { label: "$300,000", value: 300000},
  { label: "$500,000", value: 500000},
  { label: "$700,000", value: 700000},
  { label: "$900,000", value: 900000},
  { label: "$1,000,000", value: 1000000},
  { label: "$1,500,000", value: 1500000},
  { label: "$2,000,000", value: 2000000},
  { label: "$2,500,000", value: 2500000},
  { label: "$3,000,000+", value: 10000000}
]

const bed_bath_park = [
  { label: "Any", value: ''},
  { label: "1", value: 1},
  { label: "2", value: 2},
  { label: "3", value: 3},
  { label: "4", value: 4},
  { label: "5", value: 5},
  { label: "6", value: 6}
]

class PropertySearch extends Component {

  state = {
    type: this.props.type,
    price_min: null,
    price_max: null,
    bedrooms: null,
    bathrooms: null,
    carparks: null,
  }

  componentDidMount() {

  }

  handleChange = (option, field) => {
    //console.log(option, field)
    switch (field && field.action) {
      case 'select-option':
        let state = {}
        state[field.name] = option
        this.setState(state, () => {
          this.props.callback && this.props.callback(this.state)
        })
        break
      default:
        break
    }

  }

  _renderShort() {
    return (
      <div className='property-search'>
        <form action='/buy'>
          <div className='property-search__inputs'>
            <div className='property-search__input property-search__input--large'>
              <input type='text' name='suburbs' placeholder='Search Properties & Suburbs' />
            </div>
            <button type='submit'>Search</button>
          </div>
        </form>
      </div>
    )
  }

  _renderExtended() {
    let { price_min, price_max, bedrooms, bathrooms, carparks } = this.state
    return (
      <div className='property-search'>
        <form onSubmit={e => e.preventDefault()}>
          <div className='property-search__inputs'>
            <div className='property-search__input property-search__input--large property-search__input--desktop'>
              <input type='text' placeholder='Search Properties & Suburbs' />
            </div>
            <div className='property-search__input property-search__input--price'>
              <Select
                options={priceMin}
                onChange={this.handleChange}
                id="price_min"
                name="price_min"
                value={price_min}
                placeholder="Any"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            <div className='property-search__input property-search__input--price'>
              <Select
                options={priceMax}
                onChange={this.handleChange}
                id="price_max"
                name="price_max"
                value={price_max}
                placeholder="Any"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            <div className='property-search__input property-search__input--size'>
              <Select
                options={bed_bath_park}
                onChange={this.handleChange}
                id="bedrooms"
                name="bedrooms"
                value={bedrooms}
                placeholder="Any"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            <div className='property-search__input property-search__input--size'>
              <Select
                options={bed_bath_park}
                onChange={this.handleChange}
                id="bathrooms"
                name="bathrooms"
                value={bathrooms}
                placeholder="Any"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>
            <div className='property-search__input property-search__input--size'>
              <Select
                options={bed_bath_park}
                onChange={this.handleChange}
                id="carparks"
                name="carparks"
                value={carparks}
                placeholder="Any"
                className="react-select-container"
                classNamePrefix="react-select"
              />
            </div>

            <div className='property-search__input property-search__input--large property-search__input--mobile'>
              <input type='text' placeholder='Search Properties & Suburbs' />
            </div>
            <button type='submit'>Search</button>
          </div>
        </form>
      </div>
    )
  }

  render() {
    return this.props.display === 'extended' ? this._renderExtended() : this._renderShort()
  }
}

export default PropertySearch
