import React from 'react'
import { Link } from 'gatsby'

import PropertyCard from './cards/property'

const RelatedProperties = ({ properties, link }) => (
  <>
    <div className='row'>
      { properties && properties.edges.map((item, i) => {
        let props = {
          image: item.node.acf.rex_data.images && item.node.acf.rex_data.images[0].url,
          title: item.node.acf.rex_data.address.formats.street_name_number_w_suburb,
          link: `/property/${item.node.slug}/`,
          bedrooms: item.node.acf.rex_data.attributes.bedrooms,
          bathrooms: item.node.acf.rex_data.attributes.bathrooms,
          total_car_accom: item.node.acf.rex_data.attributes.total_car_accom,
        }
        return (
          <div className='col' key={i}>
            <PropertyCard {...props} />
          </div>
        )
      }) }
    </div>
    { link &&
      <div className='row'>
        <Link to='/buy' className='btn'>More — Properties for Sale</Link>
      </div>
    }
  </>
)

export default RelatedProperties
