import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Instagram, Facebook, Youtube, Twitter, Pinterest, LinkedIn } from '../components/icons'

class SocialLinks extends Component {
  render() {
    let { options } = this.props.data.wordpressAcfOptions
    return (
      <div className='social__links'>
        <p>Want more — Get Social</p>
        <div>
          { options.facebook && <a href={ options.facebook } target='_blank' rel='nofollow noopener noreferrer'><Facebook /></a> }
          { options.instagram && <a href={ options.instagram } target='_blank' rel='nofollow noopener noreferrer'><Instagram /></a> }
          { options.linkedin && <a href={ options.linkedin } target='_blank' rel='nofollow noopener noreferrer'><LinkedIn /></a> }
          { options.youtube && <a href={ options.youtube } target='_blank' rel='nofollow noopener noreferrer'><Youtube /></a> }
          { options.twitter && <a href={ options.twitter } target='_blank' rel='nofollow noopener noreferrer'><Twitter /></a> }
          { options.pinterest && <a href={ options.pinterest } target='_blank' rel='nofollow noopener noreferrer'><Pinterest /></a> }
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            instagram
            facebook
            youtube
            twitter
            pinterest
            linkedin
          }
        }
      }
      `}
    render={data => <SocialLinks data={data} {...props} />}
  />
)
