import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import PropertyStats from '../property-stats'
import Fade from 'react-reveal/Fade'

class PropertyCard extends Component {
  render() {
    let { image, title, link, type } = this.props

    return (
      <Fade bottom distance={'40px'}>
        <div className={`card card--property ${ type === 'sell' ? 'card--property--sell' : '' }`}>
          <Link to={link}>
            <figure>
              { image && <img src={image} alt={title} /> }
            </figure>
            { type === 'sell' && <div className='price'><h4>Sold</h4></div> }
            <div className='info'>
              <h4>{ title }</h4>
              <PropertyStats color={'#ffffff'} {...this.props} />
            </div>
          </Link>
        </div>
      </Fade>
    )
  }
}

PropertyCard.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  bedrooms: PropTypes.string,
  bathrooms: PropTypes.string,
  total_car_accom: PropTypes.string,
}

PropertyCard.defaultProps = {
  image: ``,
  title: ``,
  link: ``,
  bedrooms: '',
  bathrooms: '',
  total_car_accom: '',
  type: '',
  price: '',
}

export default PropertyCard
