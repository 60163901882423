import React, { Component } from 'react'
import { Link, graphql, StaticQuery } from 'gatsby'

import { MiniPhone, MiniMail } from './icons'

class TeamMember extends Component {

  static defaultProps = {
    rex_data: {},
    large: false,
    link: false,
  }

  renderImage(imageSrc) {
    let { rex_data, link } = this.props
    if (link) {
      return (
        <Link to={`/about#${rex_data.first_name}-${rex_data.last_name}`} className='team-member__image'>
          { imageSrc && <img src={imageSrc} alt={rex_data.first_name} /> }
        </Link>
      )
    }
    return (
      <div className='team-member__image'>
        { imageSrc && <img src={imageSrc} alt={rex_data.first_name} /> }
      </div>
    )
  }

  render() {
    let { rex_data, large } = this.props
    rex_data = rex_data || {}

    let item = this.props.data.team.edges.find((edge, i) => edge.node.acf.rex_data.email === rex_data.email)

    if (!item) return null

    let { position, picture, large_picture, blurb } = item.node.acf

    let imageSrc = picture && picture.localFile && picture.localFile.childImageSharp.original.src

    if (large && large_picture) imageSrc = large_picture.localFile.childImageSharp.original.src

    let phone = rex_data.settings.phone_mobile || rex_data.settings.phone_direct

    return (
      <div className='team-member' onClick={this.props.onClick}>
        { this.renderImage(imageSrc) }
        <div className='team-member__content'>
          <div><strong>{ rex_data.first_name } { rex_data.last_name }</strong></div>
          <div>{ position || rex_data.settings.position || 'Licensed Salesperson' }</div>
          <div className='team-member__contact'>
            { phone &&
              <div>
                <MiniPhone />
                <a href={`tel:${phone}`}>{ phone }</a>
              </div>
            }
            { rex_data.email &&
              <div>
                <MiniMail />
                <a href={`mailto:${rex_data.email}`}>Email Agent</a>
              </div>
            }
          </div>
          { blurb && <div className='team-member__blurb' dangerouslySetInnerHTML={{__html: blurb}} /> }
        </div>
      </div>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      {
        team: allWordpressWpTeam( sort: {fields: [menu_order], order: ASC}) {
          edges {
            node {
              title
              acf {
                rex_data {
                  first_name
                  last_name
                  email
                  settings {
                    phone_direct
                    phone_mobile
                    email_signature
                    position
                  }
                }
                position
                blurb
                picture {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
                large_picture {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      `}
    render={data => <TeamMember data={data} {...props} />}
  />
)

