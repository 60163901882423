import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import markerIcon from '../assets/images/marker.svg'

import greyStyles from '../utils/map-style'

class Map extends Component {

  render() {
    // Don't render SSR
    if (typeof window === 'undefined') return (<section className='map' />);

    const location = { lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng) };

    const MapWithAMarker = withScriptjs(withGoogleMap(props =>
      <GoogleMap
        defaultZoom={16}
        defaultCenter={location}
        options={{
          mapTypeControl: false,
          fullscreenControl: false,
          rotateControl: false,
          clickableIcons: true,
          scrollwheel:false,
          styles:greyStyles
        }}
      >
        <Marker
          position={location}
          icon={{ url: markerIcon, size: {width: 64, height: 108}, anchor: {x:32 , y: 54}, scaledSize: {width: 32, height: 54} }}
        />
      </GoogleMap>
    ));

    return (
      <div className='map__container'>
        <MapWithAMarker
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAgVHpCJ_i6EsbEFXIPlGcEmj_7MFhtCNc&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%`, margin: `0 0 20px` }} />}
          mapElement={<div style={{ height: `100%` }} className={'map__map'} />}
        />
      </div>
    );
  }
}

Map.propTypes = {
  lat: PropTypes.string,
  lng: PropTypes.string,
}

Map.defaultProps = {
  lat: '-37.7962213',
  lng: '144.9779475',
}

export default Map
